<template>
  <div class="dev-group">
    <h2 class="group-title">Text</h2>

    <h3 class="group-title">Font Familys</h3>

    <p class="font-weight-light">Light</p>
    <p class="font-weight-light"><i>Light italic</i></p>
    <p>Regular</p>
    <p><i>Regular italic</i></p>
    <p class="font-weight-bold">Medium</p>
    <p class="font-weight-bold"><i>Medium italic</i></p>

    <h3 class="group-title">Font Sizes</h3>

    <p class="text-sm">Paragraph, small - Lorem ipsum dolor sit <a href="#">amet consectetur</a> adipisicing elit. A quam dignissimos consequatur architecto recusandae similique. Ea itaque, harum excepturi doloribus inventore voluptatum officia similique optio possimus magnam enim aperiam eum?</p>
    <p>Paragraph, normal - Lorem ipsum dolor sit <a href="#">amet consectetur</a> adipisicing elit. A quam dignissimos consequatur architecto recusandae similique. Ea itaque, harum excepturi doloribus inventore voluptatum officia similique optio possimus magnam enim aperiam eum?</p>
    <p class="text-lg">Paragraph, large - Lorem ipsum dolor sit <a href="#">amet consectetur</a> adipisicing elit. A quam dignissimos consequatur architecto recusandae similique. Ea itaque, harum excepturi doloribus inventore voluptatum officia similique optio possimus magnam enim aperiam eum?</p>

    <h3 class="group-title">Headings</h3>

    <h1>Heading1 <small class="text-muted">With faded secondary text</small></h1>
    <h2>Heading2 <small class="text-muted">With faded secondary text</small></h2>
    <h3>Heading3 <small class="text-muted">With faded secondary text</small></h3>
    <h4>Headi ng4 <small class="text-muted">With faded secondary text</small></h4>
    <h5>Heading5 <small class="text-muted">With faded secondary text</small></h5>
    <h6>Heading6 <small class="text-muted">With faded secondary text</small></h6>

    <h3 class="group-title">Display</h3>

    <p class="display-1">Display 1</p>
    <p class="display-2">Display 2</p>
    <p class="display-3">Display 3</p>
    <p class="display-4">Display 4</p>

    <h3 class="group-title">Lead</h3>

    <p class="lead">Leadtext - Quo voluptatem vero laboriosam itaque saepe quod, impedit ducimus voluptatibus consectetur nam fugit ab.</p>

    <h3 class="group-title">Inline Text Elements</h3>

    <p>You can use the mark tag to <mark>highlight</mark> text.</p>
    <p><del>This line of text is meant to be treated as deleted text.</del></p>
    <p><s>This line of text is meant to be treated as no longer accurate.</s></p>
    <p><ins>This line of text is meant to be treated as an addition to the document.</ins></p>
    <p><u>This line of text will render as underlined</u></p>
    <p><small>This line of text is meant to be treated as fine print.</small></p>
    <p><strong>This line rendered as bold text.</strong></p>
    <p><em>This line rendered as italicized text.</em></p>

    <h3 class="group-title">Abbreviations</h3>

    <p><abbr title="attribute">attr</abbr></p>
    <p><abbr title="HyperText Markup Language" class="initialism">HTML</abbr></p>

    <h3 class="group-title">Blockquotes</h3>

    <blockquote class="blockquote">
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante.
      <footer class="blockquote-footer">Someone famous in <cite title="Source Title">Source Title</cite></footer>
    </blockquote>

    <h3 class="group-title">Lists</h3>

    <h4>Unordered list</h4>

    <ul>
      <li>Lorem ipsum</li>
      <li>Phasellus iaculis</li>
    </ul>

    <h4>Ordered list</h4>

    <ol>
      <li>Lorem ipsum</li>
      <li>Phasellus iacolis</li>
    </ol>

    <h4>Definition list</h4>

    <dl>
      <dt>Lorem ipsum</dt>
      <dd>Phasellus iacodds</dd>
      <dt>Lorem ipsum</dt>
      <dd>Phasellus iacodds</dd>
    </dl>

    <h4>Unstyled</h4>

    <ul class="list-unstyled">
      <li>Lorem ipsum dolor sit amet</li>
      <li>Nulla volutpat aliquam velit
        <ul>
          <li>Phasellus iaculis neque</li>
          <li>Purus sodales ultricies</li>
        </ul>
      </li>
      <li>Faucibus porta lacus fringilla vel</li>
      <li>Aenean sit amet erat nunc</li>
    </ul>

    <h4>Inline</h4>

    <ul class="list-inline">
      <li class="list-inline-item">Lorem ipsum</li>
      <li class="list-inline-item">Phasellus iaculis</li>
    </ul>

    <h4>Description list alignment</h4>

    <dl class="row">
      <dt class="col-sm-3">Description lists</dt>
      <dd class="col-sm-9">A description list is perfect for defining terms.</dd>

      <dt class="col-sm-3">Euismod</dt>
      <dd class="col-sm-9">
        <p>Vestibulum id ligula porta felis euismod semper eget lacinia odio sem nec elit.</p>
        <p>Donec id elit non mi porta gravida at eget metus.</p>
      </dd>

      <dt class="col-sm-3">Nesting</dt>
      <dd class="col-sm-9">
        <dl class="row">
          <dt class="col-sm-4">Nested definition list</dt>
          <dd class="col-sm-8">Aenean posuere, tortor sed cursus feugiat, nunc augue blandit nunc.</dd>
        </dl>
      </dd>
    </dl>
  </div>
</template>

<script>
export default {
  name: 'DevText'
}
</script>
